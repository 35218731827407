import React from "react"
import PropTypes from "prop-types"
import { jsx } from "@emotion/react"
import ValidatedForm, {
  useFormField,
  validateEmail,
} from "@coreyward/validated-form"
import Button from "components/Button"
import Checkbox from "components/Checkbox"
import theme from "theme"

const submitToHubspot = async (formGuid, email) =>
  window.fetch(
    `https://api.hsforms.com/submissions/v3/integration/submit/7528527/${formGuid}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fields: [
          { name: "email", value: email },
          { name: "business_unit", value: "Nature's Heritage" },
        ],
        skipValidation: true,
      }),
    }
  )

const NewsletterSignup = ({ className }) => (
  <div
    css={{ background: "inherit", position: "relative", zIndex: 2 }}
    className={className}
  >
    <ValidatedForm
      data-netlify="true"
      name="newsletter-signup"
      netlify-honeypot="bot-field"
      method="POST"
      onSubmit={({ fields }) =>
        submitToHubspot(
          "2684e11e-5157-4baa-a5e6-d6287e2fd95c",
          fields.email.value
        ).then(response => response.ok || true)
      }
      renderSuccess={
        <div
          css={{
            ...theme.h3,
            lineHeight: 1.3,
            maxWidth: "45ch",
            textAlign: "center",
            margin: "0 auto",
            padding: "0 30px",
          }}
        >
          You’re all set. Keep an eye on your email for updates.
        </div>
      }
    >
      <div css={{ maxWidth: 700, margin: "0 auto" }}>
        <h2 css={{ ...theme.h2, textAlign: "center", marginBottom: 44 }}>
          Stay Up To Date
        </h2>

        <div css={{ display: "flex", alignItems: "stretch" }}>
          <Input
            name="email"
            type="email"
            label="Your Email"
            validator={validateEmail}
            css={{
              flex: "1 1 auto",
              height: "100%",
              input: {
                height: "100%",
              },
            }}
          />
          <Button css={{ flex: "0 0 auto" }}>Submit</Button>
        </div>

        <TermsField />
      </div>
    </ValidatedForm>
  </div>
)

export default NewsletterSignup

NewsletterSignup.propTypes = {
  className: PropTypes.string,
}

const Input = ({
  name,
  label,
  type,
  validator,
  disabled,
  className,
  ...rest
}) => {
  const {
    value = "",
    errorMessage,
    ...inputProps
  } = useFormField({
    name,
    validator,
    required: true,
  })

  return (
    <div css={{ position: "relative" }} className={className}>
      <label
        htmlFor={rest.id || `${name}-field`}
        css={{
          ...theme.h5,
          fontSize: 9,
          color: theme.greyBrown,
          transition: "opacity 400ms",
          opacity: value ? 0.8 : 0,
          position: "absolute",
          bottom: "calc(100% + 4px)",
        }}
      >
        {label}
      </label>

      {jsx(type === "textarea" ? "textarea" : "input", {
        id: rest.id || `${name}-field`,
        name,
        value,
        type: type === "textarea" ? null : type,
        required: true,
        placeholder: label,
        css: {
          ...theme.h5,
          appearance: "none",
          display: "block",
          width: "100%",
          height: "auto",
          border: `2px solid ${errorMessage ? theme.redOrange : theme.gray2}`,
          borderRadius: 0,
          background: theme.white,
          color: theme.black,
          padding: "20px 15px",
          opacity: disabled && 0.4,
          userSelect: disabled && "none",
          "::placeholder": {
            opacity: 0.4,
          },
          ":focus": {
            outline: 0,
            borderColor: errorMessage ? theme.redOrange : theme.black,
          },

          // Prevent webkit highlighting
          transition: "background-color 5000s ease-in-out 0s",
          ":-webkit-autofill": {
            WebkitTextFillColor: theme.greyBrown,
          },
        },
        disabled,
        ...inputProps,
        ...rest,
      })}

      {errorMessage && (
        <div
          css={{
            ...theme.h5,
            fontSize: 9,
            color: theme.redOrange,
            position: "absolute",
            top: "calc(100% + 4px)",
            left: 0,
          }}
        >
          {errorMessage}
        </div>
      )}
    </div>
  )
}

Input.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  validator: PropTypes.func,
  disabled: PropTypes.bool,
  className: PropTypes.string,
}

const TermsField = () => {
  const name = "termsAgreed"
  const defaultChecked = false
  const checkedValue = "Agreed"
  const uncheckedValue = false

  const { value, errorMessage, onChange } = useFormField({
    name,
    required: true,
    defaultValue: defaultChecked ? checkedValue : uncheckedValue,
  })

  const handleChange = () => {
    onChange({
      target: { name, value: value ? uncheckedValue : checkedValue },
    })
  }

  return (
    <label
      css={{
        display: "flex",
        cursor: "pointer",
        maxWidth: 430,
        marginTop: 24,
        [theme.max(450)]: {
          padding: "0 30px",
        },
      }}
    >
      <Checkbox
        active={!!value}
        onChange={handleChange}
        css={
          errorMessage && {
            borderColor: theme.redOrange,
          }
        }
      />
      <div css={{ ...theme.h5, marginLeft: 10 }}>
        I want to receive the latest news, events, happenings from Nature’s
        Heritage.
      </div>
    </label>
  )
}
