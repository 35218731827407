import React from "react"
import PropTypes from "prop-types"
import theme from "theme"

const Checkbox = ({ active, ...props }) => (
  <input
    type="checkbox"
    checked={active}
    css={{
      appearance: "none",
      flexShrink: 0,
      border: "2px solid",
      background: theme.white,
      width: 24,
      height: 24,
      display: "block",
      position: "relative",
      cursor: "pointer",
      ":after": active && {
        content: "''",
        position: "absolute",
        top: 3,
        left: 3,
        right: 3,
        bottom: 3,
        background: theme.stampRed,
      },
    }}
    {...props}
  />
)

export default Checkbox

Checkbox.propTypes = {
  active: PropTypes.bool,
}
