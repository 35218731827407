import PropTypes from "prop-types"

export default PropTypes.shape({
  asset: PropTypes.oneOfType([
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
    }).isRequired,
    PropTypes.shape({
      _ref: PropTypes.string.isRequired,
    }).isRequired,
  ]).isRequired,
  hotspot: PropTypes.object,
  crop: PropTypes.object,
})
