import React from "react"
import { Link } from "gatsby"
import InstagramLink from "components/InstagramLink"
import Wordmark from "images/Wordmark.svg"
import theme from "theme"

const Footer = () => (
  <footer
    css={{
      background: theme.blueGreen,
      color: theme.tan,
      padding: 120,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
      position: "relative",
      zIndex: 2,
      [theme.mobile]: {
        padding: "50px 30px",
      },
    }}
  >
    <Wordmark
      css={{ width: 340, marginBottom: 56, [theme.mobile]: { width: 274 } }}
    />

    <InstagramLink />

    <div
      css={{
        ...theme.h5,
        marginTop: 125,
        lineHeight: 1.4,
        letterSpacing: "0.15em",
        a: {
          textDecoration: "underline",
        },
        [theme.mobile]: {
          marginTop: 56,
        },
      }}
    >
      <div css={{ marginBottom: 12 }}>A Brand from MariMed Inc.</div>
      <div css={{ marginBottom: 125 }}>
        <Link to="/privacy/">Privacy</Link>
        <Spacer />
        <Link to="/terms-and-conditions/">Terms &amp; Conditions</Link>
      </div>
    </div>
    <div>
      These product names and accompanying statements are not intended to represent a medical or therapeutic claim. These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease. Package image is representative only. Packaging in your jurisdiction may appear differently in order to comply with all applicable laws and regulations.
    </div>
  </footer>
)

export default Footer

const Spacer = () => (
  <span
    css={{
      display: "inline-block",
      margin: "0 0.75em",
    }}
  >
    |
  </span>
)
