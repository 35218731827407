import React from "react"
import PropTypes from "prop-types"
import theme from "theme"

const InstagramLink = ({ className }) => (
  <a
    href="https://www.instagram.com/naturesheritage_"
    target="_blank"
    rel="noopener noreferrer"
    css={{
      ...theme.h3,
      display: "block",
      textAlign: "center",
      fontSize: 15,
      [theme.mobile]: {
        fontSize: 13,
      },
      [theme.smol]: {
        fontSize: 10,
      },
    }}
    className={className}
    aria-label="Instagram"
  >
    <InstagramIcon
      css={{ width: 28, display: "block", margin: "0 auto 12px" }}
    />
    @naturesheritage_
  </a>
)

export default InstagramLink

InstagramLink.propTypes = {
  className: PropTypes.string,
}

const InstagramIcon = props => (
  <svg viewBox="0 0 28 28" {...props}>
    <path
      fill="currentColor"
      d="M13.989 2.517c3.736 0 4.176.011 5.648.077 1.363.066 2.11.286 2.594.484.648.253 1.12.56 1.604 1.044.495.495.791.957 1.044 1.606.187.494.418 1.23.484 2.594.066 1.473.077 1.924.077 5.65 0 3.727-.011 4.178-.077 5.651-.066 1.363-.286 2.11-.484 2.595a4.365 4.365 0 0 1-1.044 1.605 4.316 4.316 0 0 1-1.604 1.044c-.495.187-1.231.418-2.594.484-1.472.066-1.923.077-5.648.077-3.725 0-4.176-.011-5.648-.077-1.363-.066-2.11-.286-2.594-.484a4.363 4.363 0 0 1-1.604-1.044 4.318 4.318 0 0 1-1.044-1.605c-.187-.495-.418-1.232-.484-2.595-.066-1.473-.077-1.924-.077-5.65 0-3.727.011-4.178.077-5.651.066-1.363.286-2.11.484-2.594a4.121 4.121 0 0 1 1.033-1.595c.483-.494.956-.791 1.604-1.044.495-.187 1.231-.418 2.594-.484 1.483-.066 1.923-.088 5.659-.088Zm0-2.517c-3.802 0-4.275.011-5.77.088-1.483.066-2.505.297-3.395.649a6.643 6.643 0 0 0-2.472 1.616A6.646 6.646 0 0 0 .736 4.826C.385 5.716.154 6.74.088 8.223.01 9.718 0 10.191 0 13.994c0 3.804.011 4.277.088 5.772.066 1.495.308 2.507.648 3.397a6.914 6.914 0 0 0 1.616 2.484 6.91 6.91 0 0 0 2.483 1.616c.89.341 1.912.583 3.396.65C9.725 27.977 10.198 28 14 28s4.275-.011 5.77-.088c1.494-.066 2.505-.308 3.395-.649a6.91 6.91 0 0 0 2.483-1.616 6.914 6.914 0 0 0 1.616-2.484c.34-.89.582-1.913.648-3.397.066-1.495.088-1.968.088-5.771 0-3.804-.011-4.277-.088-5.772-.066-1.495-.308-2.506-.648-3.397a6.915 6.915 0 0 0-1.616-2.484A6.913 6.913 0 0 0 23.165.726c-.89-.341-1.912-.583-3.396-.649C18.264.011 17.791 0 13.99 0Z"
    />
    <path
      fill="currentColor"
      d="M13.99 6.805a7.19 7.19 0 0 0-7.188 7.19c0 3.968 3.22 7.189 7.187 7.189a7.191 7.191 0 0 0 7.187-7.19 7.19 7.19 0 0 0-7.187-7.19Zm0 11.861a4.663 4.663 0 0 1-4.66-4.66 4.663 4.663 0 0 1 4.66-4.662 4.663 4.663 0 0 1 4.658 4.661 4.656 4.656 0 0 1-4.659 4.661ZM21.462 8.212a1.682 1.682 0 1 0-.001-3.364 1.682 1.682 0 0 0 0 3.364Z"
    />
  </svg>
)
