import React, { useCallback, useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { useSpring, useVelocity, useViewportScroll } from "framer-motion"
import LayoutContext from "context/LayoutContext"
import Header from "components/Header"
import Footer from "components/Footer"
import NewsletterSignup from "components/NewsletterSignup"
import useElementDimensions from "hooks/useElementDimensions"
import theme from "theme"

const Layout = ({ title, ...props }) => {
  const sticky = useStickyHeader()
  const [stickyEnabled, setStickyEnabled] = useState(true)
  const headerRef = useRef()
  const headerDims = useElementDimensions(headerRef || {}, 100)

  const setSticky = useCallback(
    value =>
      setStickyEnabled(prev =>
        typeof value === "function" ? value(prev) : value
      ),
    []
  )

  return (
    <LayoutContext.Provider
      value={{
        stickyHeader: stickyEnabled && sticky,
        setSticky,
        headerRef,
        headerDims,
      }}
    >
      <Header ref={headerRef} title={title} />

      <main {...props} />

      <NewsletterSignup
        css={{
          padding: "130px 30px",
          background: theme.gray1,
          [theme.max(450)]: { padding: "60px 0" },
        }}
      />
      <Footer />
    </LayoutContext.Provider>
  )
}

export default Layout

Layout.propTypes = {
  title: PropTypes.string,
}

const useStickyHeader = () => {
  const ref = useRef()
  const { scrollY } = useViewportScroll()
  const scrollSpring = useSpring(scrollY, { mass: 5, damping: 30 })
  const scrollVelocity = useVelocity(scrollSpring)
  const [sticky, setSticky] = useState(true)

  useEffect(
    () =>
      scrollY.onChange(scrollY => {
        const velocity = scrollVelocity.get()

        if (scrollY < 200 || (velocity < -250 && !ref.current)) {
          setSticky(true)
          ref.current = true
        } else if (velocity > 125 && ref.current) {
          setSticky(false)
          ref.current = false
        }
      }),
    [scrollY, scrollVelocity]
  )

  return sticky
}
